import React from "react"
import * as PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TraveItem from "../components/travel-item/travel-item"
import BgMap from "../components/BgMap"
import Seo from "../components/seo"
import PagesHero from "../components/pages"
import Section from "../components/section"
import { FormattedMessage } from "react-intl"

import { animated, useSpring, config } from "react-spring"

/** @jsx jsx */
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"

const propTypes = {
  data: PropTypes.object.isRequired,
}

const TravelsTypeTemplate = props => {
  const travelType = props.data.contentfulTravelType
  const { typeTitle, typeShortDescription, travels } = travelType

  const titleProps = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: "translate3d(0, -30px, 0)" },
    to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
  })

  const spanProps = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const PageTitle = styled(animated.h1)`
    marginbottom: 60;
  `
  const ShortTypeDesc = styled(animated.span)``
  return (
    <Layout data={props.data} location={props.location}>
      <Seo title={typeTitle} description={typeShortDescription} />
      <PagesHero>
        <BgMap Size={"contain"} css={{ marginBottom: 60 }}>
          <Section>
            <div
              css={{
                marginBottom: 60,
              }}
              className="has-text-centered"
            >
              <PageTitle className="primary-title" style={titleProps}>
                {typeTitle}
              </PageTitle>
              <ShortTypeDesc style={spanProps}>
                {typeShortDescription}
              </ShortTypeDesc>
            </div>
            <div className="columns is-multiline">
              {travels &&
                travels.map((node, i) => <TraveItem node={node} key={i} />)}
            </div>
          </Section>
        </BgMap>
      </PagesHero>
    </Layout>
  )
}

TravelsTypeTemplate.propTypes = propTypes

export default TravelsTypeTemplate

export const pageQuery = graphql`
  query categoryQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulTravelType(id: { eq: $id }) {
      id
      typeTitle
      typeShortDescription
      travels {
        title
        shortDescription
        photo {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        node_locale
        slug
        isActive
        isHotDeals
        price
        dealsPrice
        travelType {
          slug
          typeTitle
        }
      }
    }
  }
`
